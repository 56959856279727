/* General styling */
.search-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .product-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .product-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    width: calc(33.333% - 20px); /* Default three-column layout */
    box-sizing: border-box;
    text-align: center;
    background-color: #fff;
    transition: transform 0.3s ease;
  }
  
  .product-card:hover {
    transform: scale(1.05);
  }
  
  .product-title {
    font-size: 18px;
    margin: 0 0 8px;
  }
  
  .distributor-logo {
    width: 100%;
    max-width: 200px;
    height: auto;
    object-fit: contain;
    margin-bottom: 10px;
  }
  
  .add-to-cart-btn {
    margin-top: 10px;
    padding: 10px 15px;
    background-color: rgb(215,31,37);
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .add-to-cart-btn:hover {
    background-color: #b71c1c;
  }
  
  /* Responsive Design */
  
  /* Medium screens (tablets) */
  @media (max-width: 992px) {
    .product-card {
      width: calc(50% - 20px); /* Two-column layout for tablets */
    }
  }
  
  /* Small screens (phones) */
  @media (max-width: 480px) {
    .product-grid {
      flex-wrap: nowrap; /* Single line scroll */
      overflow-x: auto;  /* Horizontal scroll enabled */
      gap: 10px;
      padding: 10px 0;
    }
  
    .product-card {
      width: 100%;       /* Full width for single-column layout */
      min-width: 100%;
      max-width: 100%;
      flex: 0 0 auto;
    }
  }
  