@import url("https://fonts.googleapis.com/css2?family=Kodchasan:wght@400&display=swap");

.kodchasan-regular {
  font-family: "Kodchasan", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.home-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

.welcome {
  margin-bottom: 5vh;
}

.welcome video {
  width: 100%;
  border-style: solid;
  border-color: rgb(215, 31, 37);
  max-width: 800px;
  height: auto;
  margin: 0 auto;
  display: block;
  border-radius: 50px;
  border-width: 0.5vw;
  overflow: hidden;
}

.stocks-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  margin: 20px 0;
  flex-wrap: wrap; /* Ensures wrapping for smaller screens */
}

.stock-section {
  flex: 1; /* Makes sections flexible */
  max-width: 48%; /* Limits width of each section */
  text-align: center;
}

.distributers-logos {
  display: flex;
  flex-wrap: wrap; /* Allows logos to wrap for smaller screens */
  justify-content: center;
  gap: 10px;
}

.distributer-logo {
  width: 150px;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.distributer-logo:hover {
  transform: scale(1.2);
}

.who-we-are {
  text-align: center;
}

.image-marquee {
  width: 100%;
  margin-bottom: 30px;
  overflow: hidden;
}

/* Modal Styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 1000;
}

.modal-content {
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px;
  max-width: 400px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.query-icon {
  position: fixed;
  bottom: 20px;
  left: 20px;
  cursor: pointer;
  color: rgb(106, 104, 104);
  z-index: 1000;
}

.query-icon:hover {
  color: rgba(0, 0, 0, 0.884);
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .stocks-container {
    gap: 15px; /* Adjust gap between sections */
  }

  .stock-section {
    max-width: 48%; /* Keep side-by-side alignment for tablets */
  }
}

@media (max-width: 768px) {
  .stocks-container {
    flex-direction: column; /* Stack sections vertically */
    align-items: center;
  }

  .stock-section {
    max-width: 100%; /* Allow full width for each section */
  }

  .distributer-logo {
    width: 120px; /* Adjust logo size for smaller screens */
  }
}

@media (max-width: 576px) {
  .distributer-logo {
    width: 100px; /* Further reduce logo size for mobile devices */
  }

  .home-container {
    padding: 10px; /* Reduce padding for smaller screens */
  }

  .welcome video {
    border-radius: 20px; /* Adjust border-radius for smaller screens */
  }
}

/* Remove the black shade on hover in WebKit browsers (Chrome, Safari) */
video::-webkit-media-controls-panel {
  background: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

/* Customize play/pause controls to avoid black shade */
video::-webkit-media-controls-play-button,
video::-webkit-media-controls-pause-button {
  background: transparent !important;
  border: none !important;
  opacity: 1 !important;
}

/* Remove hover effect for the control bar */
video::-webkit-media-controls {
  background-color: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

/* Remove background color for the control bar in non-WebKit browsers */
video::-moz-media-controls {
  background-color: transparent !important;
  box-shadow: none !important;
}
