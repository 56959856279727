/* CartItem.css */

.cart-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .item-details {
    display: flex;
    align-items: center;
  }
  
  .item-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin-right: 20px;
  }
  
  .item-info h3 {
    margin: 0 0 5px 0;
    font-size: 18px;
  }
  
  .item-price {
    color: #888;
  }
  
  .item-quantity {
    display: flex;
    align-items: center;
  }
  
  .quantity-btn {
    background-color: #f0f0f0;
    border: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .quantity-btn:hover {
    background-color: #e0e0e0;
  }
  
  .quantity-number {
    margin: 0 10px;
    font-size: 16px;
  }
  
  .remove-btn {
    background-color: #ff6b6b;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .remove-btn:hover {
    background-color: #ff5252;
  }