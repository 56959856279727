/* Cart.css */

.cart-container {
    font-family: Arial, sans-serif;
    max-width: 600px;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .cart-header {
    background-color: #f0f0f0;
    padding: 20px;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .cart-title {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
  }
  
  .cart-items {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  
  .cart-footer {
    background-color: #f0f0f0;
    padding: 20px;
  }
  
  .subtotal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    font-size: 18px;
  }
  
  .subtotal-amount {
    font-weight: bold;
  }
  
  .checkout-btn {
    background-color: rgb(215, 31, 37);;
    color: white;
    border: none;
    padding: 12px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
  }
  
  .checkout-btn:hover {
    transform: scale(1.2);
  }