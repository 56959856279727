@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kodchasan:wght@400&display=swap');
.kodchasan-regular {
  font-family: "Kodchasan", sans-serif;
  font-weight: 400;
  font-style: normal;
}
/* General styling */
.team-section {
  background-color: white;
  padding: 2rem;
  text-align: center;
}

.team-title {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: rgb(215, 31, 37);
}

/* Team Grid Layout */
.team-grid {
  display: flex;
  justify-content: center;
  gap: 3rem; /* Add space between cards */
  flex-wrap: wrap;
}

/* Team Card */
.team-card {
  perspective: 1000px;
  width: 250px;
  height: 300px;
  cursor: pointer;
  position: relative;
}

.team-card-inner {
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  position: relative;
}

.team-card.flipped .team-card-inner {
  transform: rotateY(180deg);
}

.team-card-front, 
.team-card-back {
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

/* Front Side */
.team-card-front {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
 
}

.team-card-front img {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-bottom: 1rem;
}

.team-card-front h3 {
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.team-card-front p {
  font-size: 1rem;
  color: #777;
}

/* Back Side */
.team-card-back {
  background-color: rgb(215, 31, 37);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  transform: rotateY(180deg);
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.team-card-back p {
  font-size: 1rem;
  line-height: 1.6;
  text-align: center;
  font-family: 'Dancing Script', cursive;
  color: aliceblue;
}
