/* Smooth scrolling for the entire document */
@import url('https://fonts.googleapis.com/css2?family=Kodchasan:wght@400&display=swap');

.kodchasan-regular {
  font-family: "Kodchasan", sans-serif;
  font-weight: 400;
  font-style: normal;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html, body {
  margin: 0 !important;
  padding: 0;
 /* height: 100%; /* Ensure body takes up full height of the viewport */ 
}

*, *::before, *::after {
  box-sizing: inherit;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.popup-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background-color: #fff;
  padding: 10px;
  width: 80%;
  height: 80%;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  position: relative;
}

.popup-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  width: 100%;
  height: 100%;
}

.popup-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ff5c5c;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-close-button:hover {
  background-color: #ff3b3b;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-footer {
  background-color: #f8f9fa;
  border-top: 1px solid #dee2e6;
  /* padding: 1rem; */
  text-align: center; /* Center text horizontally */
  position: relative; /* Ensure proper alignment */
  width: 100%;
  bottom: 0;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
